import { makeAutoObservable } from 'mobx'
import { IDocsSuggestion, IParagraph, IStep } from 'models'

import { api, DocumentationSortType } from 'utils'

class DocumentationStore {
  constructor() {
    makeAutoObservable(this)
  }

  async getChapters(pageType: number) {
    const response = await api.get(
      `api/Documentation/getChapters?pageType=${pageType}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async createChapter(title: string, pageType: number) {
    const response = await api.post(`api/Documentation/createChapter`, null, {
      params: {
        title,
        pageType,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async updateChapter(id: string, title: string) {
    // Response not full (Paragraphs is null)
    const response = await api.put(`api/Documentation/updateChapter`, null, {
      params: {
        id,
        title,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deleteChapter(id: string) {
    const response = await api.delete(`api/Documentation/deleteChapter`, {
      params: { id },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  // export const getParagraphsForChapter = async (chapterId: any) => {
  //   try {
  //     const response = await apiIdentity.get(`api/Documentation/getParagraphsForChapter?chapterId=${chapterId}`)
  //
  //     return response
  //   } catch (error) {
  //     handleAxiosErrors(error as AxiosError)
  //     throw error
  //   }
  // }

  async createParagraph(chapterId: string, title: string) {
    const data = { Title: title, Description: 'Default content' }
    const response = await api.post(`api/Documentation/createParagraph`, data, {
      params: {
        chapterId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async updateParagraph(id: string, data: IParagraph) {
    const response = await api.put(`api/Documentation/updateParagraph`, data, {
      params: {
        id,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deleteParagraph(id: string) {
    const response = await api.delete(`api/Documentation/deleteParagraph`, {
      params: { id },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async changeDocumentationSort(
    type: DocumentationSortType,
    data: string[],
    pageType: number
  ) {
    const response = await api.post(`api/Documentation/setNumber`, data, {
      params: {
        type,
        pageType,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getLastUpdateDate() {
    const response = await api.get(`api/Documentation/GetLastUpdateDate`)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async createComment(
    data: Pick<IDocsSuggestion, 'Text' | 'ParagraphId' | 'Index' | 'Length'>
  ) {
    const response = await api.post(`api/Documentation/createComment`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deleteComment(commentId: number) {
    const response = await api.delete(`api/Documentation/deleteComment`, {
      params: { commentId: String(commentId) },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async updateComment(data: IDocsSuggestion[]) {
    const response = await api.put(`api/Documentation/updateComment`, data)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async createStep(
    paragraphId: string,
    data: Pick<IStep, 'Title' | 'Subtitle' | 'Description' | 'Type'>
  ) {
    const response = await api.post(`api/Documentation/createStep`, data, {
      params: {
        paragraphId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async getStepsForParagraph(paragraphId: string) {
    const response = await api.get(`api/Documentation/getStepsForParagraph`, {
      params: {
        paragraphId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async updateStep(
    id: string,
    data: Pick<IStep, 'Title' | 'Subtitle' | 'Description' | 'Type'>
  ) {
    const response = await api.put(`api/Documentation/updateStep`, data, {
      params: { id },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }

  async deleteStep(id: number) {
    const response = await api.delete(`api/Documentation/deleteStep`, {
      params: { id },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )

    return response.data
  }
}

export default new DocumentationStore()
